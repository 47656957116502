<template>
  <div class="app-box">
    <x-screen-tab :slotsArea="slotsArea" :bindArea="selectObject.queryTypeValue" :bindYear="selectObject.year" :bindMonth="selectObject.month||0" @change="screenChange"></x-screen-tab>
    <div v-if="xhrData" class="count-title-box">总计(元)：{{xhrData.totalAmount|formatNum}}</div>
    <x-scroll-view :top="1">
      <table v-if="xhrData&&xhrData.items.length>0" class="data-table double-table">
        <colgroup>
          <col width="50%">
          <col width="50%">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>{{columnHeader}}</span>
            </th>
            <th scope="col">
              <span class="table-sort-icon" :class="[sort?'table-sort-down-icon':'table-sort-up-icon']" @click="sortEvent">总金额(元)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData.items" :key="index" @click="rowEvent(item.itemName);">
            <th scope="row">{{item.itemName}}</th>
            <td>{{item.amount|formatNum}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData&&xhrData.items.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import screenTab from "@/components/screenTab";
import { countGoodsAmount } from "@/api/reportCenter";
import noDataBg from "@/components/noDataBg";
import { formatNum } from "@/script/tools";

export default {
  data () {
    return {
      sort: true,
      columnHeader: "大区",
      xhrData: null,
      ajaxEnd: false,
      selectObject: {
        level1Item: this.$route.params.item,
        year: this.$route.params.year,
        month: parseInt(this.$route.params.month) || null,
        queryTypeValue: 1
      },
      slotsArea: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  mixins: [formatNum],
  created () {
    document.title = this.selectObject.level1Item;
    this.setEnum();
    this.getDataList();
  },
  methods: {
    sortEvent () {
      this.sort = !this.sort;
      this.xhrData.items.reverse();
    },
    getDataList () {
      countGoodsAmount(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.sort = true;
        this.ajaxEnd = true;
      });
    },
    screenChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.selectObject.queryTypeValue = data.area.code;
      switch (this.selectObject.queryTypeValue) {
        case "1":
          this.columnHeader = "大区";
          break;
        case "2":
          this.columnHeader = "省份";
          break;
        case "3":
          this.columnHeader = "城市";
          break;
        case "4":
          this.columnHeader = "业务员";
          break;
        default:
          break;
      }
      this.getDataList();
    },
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson")).QueryRangeTypeEnum;
      Object.keys(configJson).forEach(key => {
        this.slotsArea[0].values.push({
          code: key,
          name: configJson[key]
        });
      });
    },
    rowEvent (itemName) {
      this.$router.push({
        path: `/reportCenter/apparatus/goods/threeLevels/${this.selectObject.level1Item}/${itemName}/${this.selectObject.queryTypeValue}/${this.selectObject.year}/${this.selectObject.month || 0}`
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = this.selectObject.level1Item;
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [screenTab.name]: screenTab,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
</style>
